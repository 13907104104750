const I18N_SIDEMENU_PREFIX = 'modules.settings.general.sidemenu';

export const AI_EVALUATOR_AVATAR = 'AI_EVALUATOR_AVATAR';
export const CERTIFICATE = 'CERTIFICATE';
export const SIGNATURE = 'SIGNATURE';

export const SETTINGS_SIDE_MENU = (i18n) => [
  {
    title: i18n.t(`${I18N_SIDEMENU_PREFIX}.title`),
    items: [
      {
        title: i18n.t(`${I18N_SIDEMENU_PREFIX}.general`),
        slug: 'general',
      },
      {
        title: i18n.t(`${I18N_SIDEMENU_PREFIX}.maintenance`),
        slug: 'maintenance',
      },
      {
        title: i18n.t(`${I18N_SIDEMENU_PREFIX}.certificate`),
        slug: 'certificate',
      },
      {
        title: i18n.t(`${I18N_SIDEMENU_PREFIX}.onboarding`),
        slug: 'onboarding',
      },
      {
        title: i18n.t(`${I18N_SIDEMENU_PREFIX}.payment-setup`),
        slug: 'payment-setup',
      },
      {
        title: i18n.t(`${I18N_SIDEMENU_PREFIX}.zoom-setup`),
        slug: 'zoom-setup',
      },
      {
        title: i18n.t(`${I18N_SIDEMENU_PREFIX}.emails`),
        slug: 'emails',
      },
      {
        title: i18n.t(`${I18N_SIDEMENU_PREFIX}.antifraud`),
        slug: 'antifraud',
      },
      {
        title: i18n.t(`${I18N_SIDEMENU_PREFIX}.incident-level`),
        slug: 'incident-level',
      },
      {
        title: i18n.t(`${I18N_SIDEMENU_PREFIX}.tests`),
        items: [
          {
            title: i18n.t(`${I18N_SIDEMENU_PREFIX}.base-tests`),
            slug: 'base-tests',
          },
          {
            title: i18n.t(`${I18N_SIDEMENU_PREFIX}.custom-tests`),
            slug: 'custom-tests',
          },
        ],
      },
      {
        title: i18n.t(`${I18N_SIDEMENU_PREFIX}.batch-upload`),
        slug: 'batch-upload',
      },
      {
        title: i18n.t(`${I18N_SIDEMENU_PREFIX}.ai-evaluator`),
        slug: 'ai-evaluator',
      },
    ],
  },
];

export const SETTINGS_INITIAL_STATE = {
  scheduled_maintenance: false,
  maintenance_description: '',
  name_signature: '',
  subtitle_signature: '',
  logo_certificate: null,
  logo_signature: null,

  onboarding_enabled: false,

  page_setup: {
    steps: [],
  },

  shelf_life: '',
  trial_days: '',

  zoom_key: '',
  zoom_secret: '',

  mail_notify_remove_license: false,
  mail_notify_active_license: false,
  mail_invoice_receipt: false,
  mail_mark_project: false,
  mail_sign_up_by_teacher: false,
  mail_sign_up_msg: false,
  mail_forgot_password: false,

  af_closed_window_amount: '',
  af_move_focus_amount: '',

  progress_check_enabled: false,

  student_toefl_enabled: false,
  toefl_enabled: false,
  toefl_hide: false,
  hide_analysis_toefl: false,
  hide_reports_toefl: false,
  hide_practice_after_toefl: false,

  student_ielts_academic_enabled: false,
  ielts_academic_enabled: false,
  ielts_academic_hide: false,
  hide_analysis_ielts: false,
  hide_ielts_bands: false,
  ielts_master_enabled: false,
  ielts_lrw_enabled: false,
  hide_reports_ielts: false,
  hide_practice_after_ielts: false,

  student_toeic_lr_enabled: false,
  toeic_lr_enabled: false,
  toeic_lr_hide: false,
  hide_analysis_toeic: false,
  hide_reports_toeic: false,
  hide_practice_after_toeic: false,

  student_cefr_enabled: false,
  cefr_enabled: false,
  cefr_hide: false,
  has_pricing_cefr: false,
  has_practice_cefr: false,
  hide_analysis_cefr: false,
  hide_reports_cefr: false,
  hide_practice_after_cefr: false,

  student_cefr_rl_enabled: false,
  cefr_rl_enabled: false,
  cefr_rl_hide: false,
  has_pricing_cefr_rl: false,
  has_practice_cefr_rl: false,
  hide_analysis_cefr_rl: false,
  hide_reports_cefr_rl: false,
  hide_practice_after_cefr_rl: false,

  mcer_enabled: false,
  has_practice_mcer: false,
  hide_analysis_mcer: false,
  hide_practice_after_mcer: false,
  has_pricing_mcer: false,
  mcer_hide: true,
  student_mcer_enabled: false,
  rounded_mcer_certificate: false,
  hide_reports_mcer: false,

  student_a2_flyers_enabled: false,
  a2_flyers_enabled: false,
  a2_flyers_hide: false,
  hide_analysis_a2_flyers: false,
  hide_reports_a2_flyers: false,

  student_sat_enabled: false,
  sat_enabled: false,
  sat_hide: false,
  hide_analysis_sat: false,
  hide_reports_sat: false,

  student_linguaskill_general_enabled: false,
  linguaskill_general_enabled: false,
  linguaskill_general_hide: false,
  hide_analysis_linguaskill_general: false,
  hide_reports_linguaskill_general: false,

  student_linguaskill_business_enabled: false,
  linguaskill_business_enabled: false,
  linguaskill_business_hide: false,
  hide_analysis_linguaskill_business: false,
  hide_reports_linguaskill_business: false,

  max_upload_students: '',

  ai_evaluator_avatar: null,
  ai_evaluator_name: '',
  ai_evaluator_description: '',

  whitelabel_custom_tests: null,

  enable_projected_score_mode: false,
};

export const createSettingsDataRequestModel = (data) => {
  const checkAiEvaluatorFields = [
    'ai_evaluator_name',
    'ai_evaluator_description',
  ];

  const fileFields = [
    'logo_certificate',
    'logo_signature',
    'ai_evaluator_avatar',
  ];

  const notSendFields = ['pageSetup', 'page_setup', 'onboarding_locked'];

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] !== null) {
      const invalidAiEvaluatorFields =
        checkAiEvaluatorFields.includes(key) && !data[key];

      const invalidFileFields =
        fileFields.includes(key) &&
        (typeof data[key] == 'string' || typeof data[key] == 'undefined');

      if (
        invalidFileFields ||
        notSendFields.includes(key) ||
        invalidAiEvaluatorFields
      ) {
        return;
      }

      formData.set(key, data[key]);
    }
  });

  const pageSetup = data?.page_setup?.steps || data?.page_setup;

  if (pageSetup) {
    formData.set('page_setup', JSON.stringify({ steps: pageSetup }));
  }

  return formData;
};

export const getImageType = (image) => {
  switch (image) {
    case 'logo_certificate':
      return CERTIFICATE;
    case 'logo_signature':
      return SIGNATURE;
    case 'ai_evaluator_avatar':
      return AI_EVALUATOR_AVATAR;
  }
};
